import axios from 'axios'

const primaryBaseURL = 'https://berberimicin.onrender.com/api'
const fallbackBaseURL = 'https://unicuthairdesign-production.up.railway.app/api'

// Keep-alive endpoint'lerinin URL'leri
const primaryKeepAliveURL = 'https://berberimicin.onrender.com/keep-alive'
// const fallbackKeepAliveURL = 'https://unicuthairdesign-production.up.railway.app/keep-alive'

let serverCheckPromise = null // Sunucu durumu kontrolü için paylaşılacak promise (sayfayı yenilediğim zamanlar 1 den fazla keep-alive isteği oluşturulabiliyordu örneğin 4 kere ve bu gereksiz, bunu çözmek için kullanıyorum. Her sayfa yenilemede 4 adet keep-alive isteği yapılmasının nedeni, uygulamanızdaki birden fazla bileşenin axiosInstance'ı eşzamanlı olarak kullanmasıdır. Aşağıdaki çözüm, aynı anda birden fazla isteğin yapılmasını engeller ve sonucu paylaşır(checkPrimaryServerStatus fonkisonu içerisinde kullanılıyor.) u ayar projenizi olumsuz etkilemez ve birden fazla müşterinin aynı anda siteye giriş yapmasını, randevu almasını veya görüntülemesini engellemez. Bunun nedeni, checkPrimaryServerStatus fonksiyonunun sadece sunucunun durumunu kontrol etmek için kullanılan bir yardımcı fonksiyon olmasıdır ve müşteri işlemleriyle doğrudan bir bağı yoktur. )
let isPrimaryServerHealthy = true // Başlangıçta birinci sunucu sağlıklı kabul ediliyor
let lastCheckTime = 0 // Son kontrol zamanı
const CHECK_INTERVAL = 60000 // Sunucu durumunu 60 saniyede bir kontrol et

// Sunucu durumunu kontrol etme fonksiyonu
const checkPrimaryServerStatus = async () => {
  const now = Date.now()
  if (now - lastCheckTime < CHECK_INTERVAL) {
    // Eğer son kontrol üzerinden yeterli zaman geçmediyse, önbellek durumunu döndür
    return isPrimaryServerHealthy
  }

  // Eğer zaten bir kontrol işlemi başlatılmışsa, o işlemi bekle
  if (!serverCheckPromise) {
    serverCheckPromise = (async () => {
      try {
        const response = await axios.get(primaryKeepAliveURL)
        isPrimaryServerHealthy = response.status === 200 // Sunucu sağlıklıysa true olarak güncelle
      } catch (error) {
        isPrimaryServerHealthy = false // Sunucuya ulaşılamıyorsa false olarak güncelle
      }
      lastCheckTime = now // Son kontrol zamanını güncelle
      serverCheckPromise = null // Promise işlemi tamamlandıktan sonra sıfırla
      return isPrimaryServerHealthy
    })()
  }
  return serverCheckPromise // Devam eden kontrol işlemini bekle
}

const axiosInstance = axios.create({
  baseURL: primaryBaseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('myToken')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

// Sunucu sağlığına göre baseURL belirleme
axiosInstance.interceptors.request.use(
  async (config) => {
    const isHealthy = await checkPrimaryServerStatus()
    if (!isHealthy) {
      config.baseURL = fallbackBaseURL // Yedek URL'yi kullan
    }
    return config
  },
  (error) => Promise.reject(error)
)

// Yanıtları ele alma
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (
      error.config &&
      !originalRequest._retry &&
      (error.response?.status >= 500 || error.code === 'ECONNABORTED') // Ağ hatası veya sunucu hatası
    ) {
      originalRequest._retry = true // Yeniden deneme işaretini koy
      try {
        return await axiosInstance(originalRequest) // Yedek URL ile isteği tekrar gönder
      } catch (fallbackError) {
        return Promise.reject(fallbackError) // Yedek sunucu da başarısızsa hatayı döndür
      }
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
